@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Lato:wght@100;400;700;900&display=swap");

/* RESET */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  font-family: "Lato", sans-serif;
}

::placeholder {
  opacity: 0.5 !important;
}

/* KEY FRAMES */
@keyframes fade-in-from-top {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.header {
  background-image: linear-gradient(to right, #18a0be, #622db9);
  height: 55px;
  display: flex;
}

.logo {
  align-self: center;
  margin-left: 20px;
  margin-right: 30px;
  animation: fade-in-from-top 0.5s;
  height: 40px;
}

.header__title {
  align-self: center;
  animation: fade-in-from-top 0.5s;
}

.cv-form {
  display: flex;
  align-items: center;
  border: 5px outset rgb(30, 79, 92);
  flex-direction: column;
  padding: 35px;
  border-radius: 8px;
  margin: 5px 500px;
}

.personal-detail,
.work-exp,
.edu,
.skill {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-label {
  padding: 5px;
}

.personal-details__header,
.work-experience__header,
.education__header,
.skills__header {
  padding: 5px;
  margin: 15px 5px;
  border-bottom: 1px solid rgba(141, 168, 173, 0.5);
}

.form {
  display: flex;
}
